import { useTenantsState } from '@frontegg/react'
import { Autocomplete, TextField } from '@mui/material'
import { useTenantsListQuery } from '__generated__/graphql'
import LoadingBackdrop from './LoadingBackdrop'
import { sortBy, uniqBy } from 'lodash'

export default function TenantSelector(props: {
  onChange: (tenantId: string) => void
  selectedTenantId: string
}) {
  const { onChange, selectedTenantId } = props
  const { tenants: topLevelTenants } = useTenantsState()
  const tenantsWithSubTenants = topLevelTenants.filter(
    (tenant) => tenant.isReseller,
  )
  const { data, loading } = useTenantsListQuery({
    skip: !tenantsWithSubTenants?.length,
    variables: {
      tenantIds: tenantsWithSubTenants.map(
        (tenant) => tenant.tenantId,
      ),
    },
  })
  const tenants = sortBy(
    uniqBy(
      [
        ...(topLevelTenants?.map((tenant) => ({
          id: tenant.tenantId,
          name: tenant.name,
        })) ?? []),
        ...(data?.portalApp?.subtenants ?? []).map((tenant) => ({
          id: tenant.tenantId,
          name: tenant.name,
        })),
      ],
      'id',
    ),
    'name',
  )

  return (
    <>
      <LoadingBackdrop open={loading} invisible />
      <Autocomplete
        fullWidth
        options={tenants.map((tenant) => tenant.id)}
        disableClearable
        multiple={false}
        value={selectedTenantId}
        onChange={(_, tenantId) => onChange(tenantId)}
        getOptionLabel={(tenantId) =>
          tenants.find((tenant) => tenant.id === tenantId)?.name ??
          tenantId
        }
        renderInput={(params) => <TextField {...params} />}
        sx={{ py: 2 }}
      />
    </>
  )
}
